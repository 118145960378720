import { t } from '@/utility/localization';
import { ensureHttps } from '@/utility/stringHelper';

import { isValidUrl } from '@/pages/portal/utils/events';

import { socialMediaLinks } from './constants';

export const getSocialMediaLink = (socialUsername, socialMedia) => {
  if (isValidUrl({ url: socialUsername })) {
    return socialUsername;
  }

  return `${socialMediaLinks[socialMedia]}${socialUsername}`;
};

export const getSocialMediaUsername = (socialMediaLink) => {
  if (socialMediaLink?.includes('@')) {
    return socialMediaLink?.split('@').pop();
  }
  const splittedLink = socialMediaLink?.split('/');

  const lastValueInLink = splittedLink?.pop();

  if (!lastValueInLink) return splittedLink?.[splittedLink?.length - 1];

  return lastValueInLink;
};

export const isValidSocialMediaUrl = (
  url: string,
  platform: string
): boolean => {
  // if empty string, treat is as valid as user is not required to enter a value
  if (url === '') {
    return true;
  }

  const formattedUrl = ensureHttps(url);
  if (!isValidUrl({ url: formattedUrl })) return false;

  switch (platform) {
    case 'linkedin':
      return formattedUrl.includes('linkedin.com');
    case 'facebook':
      return formattedUrl.includes('facebook.com');
    default:
      return true;
  }
};

export const validateSocialMediaUrl = (
  type: string,
  url: string
): string => {
  if (!url) return '';

  if (!isValidSocialMediaUrl(url, type)) {
    return t(`label-should-be-a-valid-url`, {
      label: type
    });
  }

  return '';
};

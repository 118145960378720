import { t } from '@/utility/localization';

import { IconName } from '@/components/npl/Icon';

import { ListOfSocialMedia } from './types';

type initialSocialMediaType = {
  buttonText: string;
  iconName: IconName<'social'>;
  type: ListOfSocialMedia;
};

export const getInitialSocialMediaProps = (): initialSocialMediaType[] => [
  {
    type: 'instagram',
    buttonText: t('add-instagram'),
    iconName: 'instagram-neutral'
  },
  {
    type: 'linkedin',
    buttonText: t('add-linkedin'),
    iconName: 'linkedin-neutral'
  },
  {
    type: 'tiktok',
    buttonText: t('add-tiktok'),
    iconName: 'tiktok-neutral'
  }
];

export const socialMediaIcons: Record<
  ListOfSocialMedia,
  IconName<'social' | 'common'>
> = {
  instagram: 'instagram-coloured',
  twitter: 'twitter-coloured',
  linkedin: 'linkedin-coloured',
  website: 'globe-02',
  youtube: 'youtube-coloured',
  tiktok: 'tiktok-coloured',
  facebook: 'facebook-coloured',
  discord: 'discord-coloured'
};

export const socialMediaNeutral: Record<
  ListOfSocialMedia,
  IconName<'social' | 'common'>
> = {
  instagram: 'instagram-neutral',
  linkedin: 'linkedin-neutral',
  website: 'globe-02',
  youtube: 'youtube-neutral',
  tiktok: 'tiktok-neutral',
  facebook: 'facebook-neutral',
  twitter: 'twitter-neutral',
  discord: 'discord-neutral'
};

export const socialMediaLinks: Record<ListOfSocialMedia, string> = {
  instagram: 'https://www.instagram.com/',
  tiktok: 'https://www.tiktok.com/@',
  linkedin: 'https://www.linkedin.com/in/',
  facebook: 'https://www.facebook.com/',
  youtube: 'https://www.youtube.com/@',
  twitter: 'https://www.x.com/',
  discord: '',
  website: ''
};
